import {
  Component,
  ViewChild,
  OnInit,
  ElementRef,
  OnDestroy,
  HostListener
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { ConnectService } from "../services/connect.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subscription, timer } from "rxjs";
import { retry } from "rxjs/operators";
import { ErrorService } from "../services/error.service";
import { ActionService } from "../services/action.service";

@Component({
  selector: "talent-connect",
  templateUrl: "./connect.component.html",
  styleUrls: ["./sass/styles.scss"]
})
export class ConnectComponent implements OnInit, OnDestroy {
  EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  emailCtrl: FormControl;
  passwordCtrl: FormControl;
  userForm: FormGroup;

  emailLabel: boolean = false;
  passwordLabel: boolean = false;
  invalidCredentials: boolean = false;

  submitted: boolean;

  private subscription: Subscription;

  @ViewChild("background") background: ElementRef;
  @ViewChild("divForm") divCredForm: ElementRef;
  @ViewChild("ngForm") credentialsForm: ElementRef;

  formStyle = {};
  loaderStyle = {};

  constructor(
    fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private connectService: ConnectService,
    private errorService: ErrorService,
    private actionService: ActionService
  ) {
    this.submitted = true;

    this.connectService.isLoggedIn().subscribe(connected => {
      if (connected) {
        this.redirectManagement();
      }

      this.submitted = false;
    });

    this.emailCtrl = fb.control("", [Validators.required, Validators.email]);
    this.passwordCtrl = fb.control("", [Validators.required]);
    this.userForm = fb.group({
      email: this.emailCtrl,
      password: this.passwordCtrl
    });
  }

  ngOnInit() {
    this.computeStyle();

    this.subscription = this.errorService.getMessage().subscribe(message => {
      this.invalidCredentials = message;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener("window:resize", ["$event"])
  manageResize() {
    this.computeStyle();
  }

  labelFocus(type: string) {
    switch (type) {
      case "email":
        this.emailLabel = true;
        break;
      case "password":
        this.passwordLabel = true;
        break;
    }
  }

  labelFocusOut(type: string) {
    switch (type) {
      case "email":
        this.emailCtrl.value === "" ? (this.emailLabel = false) : "";
        break;
      case "password":
        this.passwordCtrl.value === "" ? (this.passwordLabel = false) : "";
        break;
    }
  }

  connect() {
    this.submitted = true;
    const res: Observable<boolean> = this.connectService.connect(
      this.emailCtrl.value,
      this.passwordCtrl.value
    );

    this.actionService
      .track({
        type: "connection",
        device: navigator.appVersion,
        browser: navigator.userAgent
      })
      .pipe(retry(3))
      .subscribe();

    res.subscribe(
      ok => {
        this.redirectManagement();
      },
      error => {
        this.submitted = false;
        if (error === "This route can only be accessed by anonymous users.") {
          this.connectService.hardlogout();
        }
      },
      () => {
        this.submitted = false;
      }
    );
  }

  private redirectManagement() {
    this.activatedRoute.queryParamMap.subscribe(e => {
      if (e.keys.length && e.has("destination")) {
        window.location.href = e.get("destination");
      } else {
        this.router.navigate(["/overview"]);
        this.submitted = false;
      }
    });
  }

  private computeStyle() {
    const h =
      this.background.nativeElement.offsetWidth < 528
        ? this.background.nativeElement.offsetHeight / 2 + "px"
        : "0";
    const mT =
      (this.background.nativeElement.offsetHeight -
        (parseInt(h) === 0
          ? this.credentialsForm.nativeElement.offsetHeight
          : parseInt(h))) /
      2;

    this.formStyle = {
      marginTop: (mT < 120 ? 120 : mT) + "px",
      height: h === "0" ? "auto" : h
    };

    this.loaderStyle = {
      marginTop:
        h === "0"
          ? this.divCredForm.nativeElement.offsetHeight / 2 - 57 + "px"
          : parseInt(h) / 2 - 57 + "px"
    };
  }
}
