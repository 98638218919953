import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class MytalentsService {
  constructor(private http: HttpClient) {}

  getList() {
    const headers = {
      Accept: "application/json"
    };

    return this.http.get("/api/mytalents", { headers });
  }
}
