import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ErrorService } from "../services/error.service";
import { ConnectService } from "../services/connect.service";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private errorService: ErrorService,
    private connectService: ConnectService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        switch (err.status) {
          case 400:
            this.errorService.error(false);
            break;
          case 403:
            this.connectService.deleteCookies();
            this.router.navigateByUrl("/connect");
            break;
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
