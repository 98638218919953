import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class GoogleAnalyticsService {
  constructor() {}

  sendEvent(category, label, action) {
    if (typeof (<any>window).ga !== "undefined") {
      (<any>window).ga("send", "event", {
        eventCategory: category,
        eventLabel: label,
        eventAction: action,
        eventValue: 10
      });
    }
  }

  sendUrlChange(event) {
    if (typeof (<any>window).ga !== "undefined") {
      (<any>window).ga("set", "page", event.urlAfterRedirects);
      (<any>window).ga("send", "pageview");
    }
  }
}
