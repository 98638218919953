import { Component, OnInit, HostListener } from "@angular/core";
import { SettingsService } from "../services/settings.service";
import { timer } from "rxjs";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./sass/styles.scss"]
})
export class SettingsComponent implements OnInit {
  notifSetting: boolean;
  responsive: boolean;
  loading: boolean;
  updated: boolean;

  constructor(private settingsservice: SettingsService) {}

  ngOnInit() {
    this.isResponsive();
    this.loading = true;
    this.updated = false;

    this.settingsservice.getNotifSetting().subscribe((notif: number) => {
      this.notifSetting = Boolean(Number(notif));
      this.loading = false;
    });
  }

  toggleNotifSetting() {
    this.loading = true;
    timer(1000).subscribe(x => {
      this.loading = false;
      this.updated = true;
    });

    this.settingsservice.toggleNotifSetting().subscribe((notif: any) => {});
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.isResponsive();
  }

  isResponsive() {
    this.responsive = window.innerWidth <= 794 ? true : false;
  }
}
