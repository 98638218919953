import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { DocumentService } from "../services/document.service";
import { ConnectService } from "../services/connect.service";
import { Router, ActivatedRoute } from "@angular/router";

interface displayObject {
  title: string;
  data: Array<{ type: string; data: any }>;
}

@Component({
  selector: "talent-document",
  templateUrl: "./document.component.html",
  styleUrls: ["./sass/styles.scss"]
})
export class DocumentComponent implements OnInit {
  data: displayObject;
  videoUrl: SafeResourceUrl;
  videoWidth: number;
  videoHeight: number;
  content: ElementRef;

  @ViewChild("Content")
  set contentDiv(c: ElementRef) {
    setTimeout(() => {
      this.content = c;
      this.getSizeContent();
    }, 0);
  }

  constructor(
    public sanitizer: DomSanitizer,
    private documentService: DocumentService,
    private route: ActivatedRoute
  ) {
    this.videoUrl = null;
    this.videoWidth = 0;
    this.videoHeight = 0;
  }

  ngOnInit() {
    let type = "";
    switch (this.route.snapshot.url[0].path) {
      case "all-about-talent":
        type = "all-about-talent";
        break;
      case "legal-notices":
        type = "legal-notices";
        break;
    }
    this.documentService.getDocument(type).subscribe((doc: Array<string>) => {
      let tmp = this.manageIframe(doc);
      tmp = this.manageTable(tmp);
      this.data = tmp;
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.getSizeContent();
  }

  getSizeContent() {
    if (this.content !== undefined) {
      this.videoWidth = this.content.nativeElement.clientWidth;
      this.videoHeight = (this.content.nativeElement.clientWidth * 9) / 16;
    }
  }

  manageIframe(doc: Array<string>): displayObject {
    let res: displayObject = {
      title: doc[0],
      data: []
    };

    let txtToManage = doc[1].split("<iframe");

    while (txtToManage.length > 1) {
      res.data.push({
        type: "text",
        data: this.deleteDivIframeApi(txtToManage[0])
      });

      let iframe = txtToManage[1].split("</iframe>");

      if (iframe.length > 1) {
        let tmp = iframe[0].split('src="');
        if (tmp.length > 1) {
          let url = this.sanitizer.bypassSecurityTrustResourceUrl(
            tmp[1].split("?")[0]
          );
          res.data.push({ type: "iframe", data: url });
        }
      }

      txtToManage = iframe[1].split("<iframe");
    }
    res.data.push({
      type: "text",
      data: this.deleteDivIframeApi(txtToManage[0])
    });

    return res;
  }

  deleteDivIframeApi(str: string): string {
    return str
      .replace(/<div data-oembed-url(.*)/gi, "")
      .replace(/<div style="max-width:320px;margin:auto;">(.*)/gi, "");
  }

  manageTable(doc: displayObject): displayObject {
    let text = doc.data;

    text.forEach(e => {
      if (e.type === "text") {
        e.data = e.data
          .replace(/<table(.*)/gi, "<div class='content__table'><table$1")
          .replace(/(.*)<\/table>/gi, "$1</table></div>");
      }
    });

    return doc;
  }
}
