import { Component, OnInit, HostListener } from "@angular/core";
import { MytalentsService } from "../services/mytalents.service";

@Component({
  selector: "app-mytalents",
  templateUrl: "./mytalents.component.html",
  styleUrls: ["./sass/styles.scss"]
})
export class MytalentsComponent implements OnInit {
  list: Array<any>;
  responsive: boolean;

  constructor(private mytalentsservice: MytalentsService) {}

  ngOnInit() {
    this.isResponsive();

    this.mytalentsservice.getList().subscribe((list: Array<any>) => {
      this.list = list;
      list.forEach(reco => {
        reco.date = new Date(parseInt(reco.created + "000"));
        reco.jobDate = new Date(parseInt(reco.jobCreated + "000"));
      });
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.isResponsive();
  }

  isResponsive() {
    this.responsive = window.innerWidth <= 794 ? true : false;
  }
}
