import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import {ConnectService} from "./connect.service";

@Injectable({
  providedIn: "root"
})
export class DocumentService {
  constructor(
      private http: HttpClient,
      private connectService: ConnectService,
  ) {}

  getDocument(type: string): Observable<Array<string>> {
    const headers = {
      Accept: "application/json"
    };
    const params = { type: type };

    return this.http.get<Array<string>>("/api/document/" + this.connectService.officeId, {
      headers,
      params
    });
  }
}
