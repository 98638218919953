export class Announce {
  id: string;
  created: string;
  date: Date;
  office: string;
  profession: string;
  team: string;
  introduction?: string;
  jobDescription?: string;
  profile?: Array<string>;
  requiredSkills?: Array<string>;
  recruitmentStrategy?: boolean;
  contractType?: string;
  parentTeam: string;
}
