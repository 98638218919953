import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "talent-filter-desktop",
  templateUrl: "./filter-desktop.component.html",
  styleUrls: ["./filter-desktop.component.scss"],
})
export class FilterDesktopComponent implements OnInit {
  @Input() sortDate: { name: string; active: boolean };
  @Input() originalOfficeFilters: Array<String>;
  @Input() originalContractFilters: Array<String>;
  @Input() originalTeamFilters: Array<String>;
  @Input() filterText: String;
  private _filterOffice: Array<String>;
  private _filterContract: Array<String>;
  private _filterTeam: Array<String>;
  private tmpOffice: Array<String>;
  private tmpTeam: Array<String>;
  private tmpContract: Array<String>;
  private checkedOffices: Array<String>;
  private checkedTeams: Array<String>;
  private checkedContracts: Array<String>;

  @Input() set filterOffice(value: Array<String>) {
    this._filterOffice = value;
    this.tmpOffice = this.originalOfficeFilters.filter(
      (item) => this.filterOffice.indexOf(item) < 0
    );
  }
  get filterOffice(): Array<String> {
    return this._filterOffice;
  }
  @Input() set filterContract(value: Array<String>) {
    this._filterContract = value;
    this.tmpContract = this.originalContractFilters.filter(
      (item) => this.filterContract.indexOf(item) < 0
    );
  }
  get filterContract(): Array<String> {
    return this._filterContract;
  }
  @Input() set filterTeam(value: Array<String>) {
    this._filterTeam = value;
    this.tmpTeam = this.originalTeamFilters.filter(
      (item) => this.filterTeam.indexOf(item) < 0
    );
  }
  get filterTeam(): Array<String> {
    return this._filterTeam;
  }
  @Output() filterEvent = new EventEmitter<any>();
  @Output() resetEvent = new EventEmitter<any>();

  displayOffice: boolean = false;
  displayTeam: boolean = false;
  displayContract: boolean = false;
  displayAll: boolean = false;
  filterToggle: boolean = false;
  dateSort: boolean = false;
  constructor() {
    this.checkedOffices = [];
    this.checkedTeams = [];
    this.checkedContracts = [];
  }

  ngOnInit() {}
  filterEventEmit($event) {
    if ($event.target.name === "") {
      $event.target.name = undefined;
    }
    this.filterEvent.emit($event);
  }

  checkClickedTeam($event) {
    if ($event.target.checked) {
      this.checkedTeams.push($event.target.value);
    } else {
      this.checkedTeams.splice(
        this.checkedTeams.indexOf($event.target.value),
        1
      );
    }
    $event.target.data = this.checkedTeams;
    this.filterEvent.emit($event);
  }
  checkClickedOffice($event) {
    if ($event.target.checked) {
      this.checkedOffices.push($event.target.value);
    } else {
      this.checkedOffices.splice(
        this.checkedOffices.indexOf($event.target.value),
        1
      );
    }
    $event.target.data = this.checkedOffices;
    this.filterEvent.emit($event);
  }

  checkClickedContract($event) {
    if ($event.target.checked) {
      this.checkedContracts.push($event.target.value);
    } else {
      this.checkedContracts.splice(
        this.checkedContracts.indexOf($event.target.value),
        1
      );
    }
    $event.target.data = this.checkedContracts;
    this.filterEvent.emit($event);
  }
  resetEventEmit($event) {
    const myForm = <HTMLFormElement>document.getElementById("filtersForm");
    myForm.reset();
    //TODO: UNCHECK ALL
    this.checkedOffices = [];
    this.checkedTeams = [];
    this.checkedContracts = [];
    this.filterToggle = false;
    this.resetEvent.emit($event);
  }
}
