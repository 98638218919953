import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root"
})
export class PopinService {
  private cookieName: string;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.cookieName = "talent_popin_disable";
  }

  getData(): Observable<Array<string>> {
    const headers = {
      Accept: "application/json"
    };

    return this.http.get<Array<string>>("/api/popin", {
      headers
    });
  }

  getDisabled(): boolean {
    return this.cookieService.get(this.cookieName) ? false : true;
  }

  disabling(): void {
    const expires = new Date(new Date().getTime() + 2000000000);

    this.cookieService.set(this.cookieName, "true", expires);
  }
}
