import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { ConnectService } from "../services/connect.service";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class LoggedInGuard implements CanActivate {
  constructor(private router: Router, private connectService: ConnectService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.connectService.isLoggedIn(true).pipe(
      map(res => {
        if (!res) {
          this.router.navigate(["/connect"]);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
