import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
  keyframes
} from "@angular/animations";

export const listAnimation = trigger("listAnimation", [
  transition("* => *", [
    query(":enter", style({ opacity: 0 }), { optional: true }),

    query(
      ":enter",
      stagger("50ms", [
        animate(
          "500ms ease-in",
          keyframes([
            style({ opacity: 0 }),
            style({ opacity: 0.5 }),
            style({ opacity: 1 })
          ])
        )
      ]),
      { optional: true }
    )
  ])
]);
