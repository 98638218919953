import {
  Component,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  AfterContentInit
} from "@angular/core";
import { Announce } from "../../class/announce";
import { PlatformLocation } from "@angular/common";
import { timer } from "rxjs";
import { fieldAnimation } from "../transition";

@Component({
  selector: "talent-share",
  templateUrl: "./share.component.html",
  styleUrls: ["./share.component.scss"],
  animations: [fieldAnimation]
})
export class ShareComponent implements OnInit, AfterContentInit {
  protocol: string;
  baseURI: string;
  copied: boolean;
  alreadyCopied: boolean;
  mailto: string;
  display: string;

  @Input() job: Announce;

  @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("linkJob") linkJob: ElementRef;

  constructor(private platformLocation: PlatformLocation) {
    this.display = "hide";
    this.copied = false;
    this.alreadyCopied = false;
    this.baseURI = (platformLocation as any).location.hostname + "/job/";
    this.protocol = (platformLocation as any).location.protocol + "//";
  }

  ngOnInit() {
    const br = "%0D%0A";
    this.mailto =
      "mailto:?subject=Ardian Job Offer" +
      "&body=" +
      "Hello," +
      br +
      br +
      "Ardian is looking for a " +
      this.job.profession +
      " and I thought this position might be of interest for you." +
      br +
      br +
      "Please click on the following link to see the job description: " +
      this.protocol +
      this.baseURI +
      this.job.id +
      br +
      br +
      "If you are interested in this position, please let me know. Should you have any questions, feel free to contact me." +
      br +
      br +
      "Thank you!";
  }

  ngAfterContentInit() {
    timer(1).subscribe(() => (this.display = "show"));
  }

  closeShare() {
    this.closeAction.emit();
  }

  copy() {
    this.copied = true;
    this.alreadyCopied = true;
    
    timer(1000).subscribe(() => (this.copied = false));
  }
}
