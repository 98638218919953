import { Component } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { GoogleAnalyticsService } from "./services/googleanalytics.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "talent-front";

  constructor(private router: Router, gaService: GoogleAnalyticsService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gaService.sendUrlChange(event);
      }
    });
  }
}
