import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  Input,
  Output
} from "@angular/core";
import { PopinService } from "../services/popin.service";
import { ConnectService } from "../services/connect.service";
import { of } from "rxjs";
import { delay } from "rxjs/operators";

@Component({
  selector: "talent-popin",
  templateUrl: "./popin.component.html",
  styleUrls: ["./popin.component.scss"]
})
export class PopinComponent implements OnInit {
  topping: {};
  popin: Array<string>;
  hide: string;

  @ViewChild("popinCtn") popinCtn: ElementRef;
  @ViewChild("disabling") disabling: ElementRef;

  constructor(
    private connectService: ConnectService,
    private popinService: PopinService
  ) {
    this.launchPopin();
  }

  private launchPopin() {
    this.connectService.isLoggedIn().subscribe(connected => {
      connected
        ? this.popinService.getData().subscribe(ppin => {
            this.popin = ppin;
            this.hide = this.popinService.getDisabled() ? "block" : "none";

            of("ok")
              .pipe(delay(1))
              .subscribe(() => {
                this.styling();
              });
          })
        : "";
    });
  }

  ngOnInit() {}

  unshow() {
    this.hide = "none";
    if (this.disabling.nativeElement.checked) {
      this.popinService.disabling();
    }
  }

  @HostListener("window:resize", ["$event"])
  manageResize() {
    this.styling();
  }

  styling() {
    if (this.popinCtn !== undefined) {
      let mod: number =
        screen.height >= this.popinCtn.nativeElement.offsetHeight + 90 ? 90 : 0;

      mod <= 0
        ? (this.topping = { top: 0 })
        : (this.topping = {
            top:
              "calc(50vh - " +
              (this.popinCtn.nativeElement.offsetWidth / 2 + mod) +
              "px)"
          });
    }
  }
}
