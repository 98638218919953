import { Component, OnInit } from "@angular/core";

@Component({
  selector: "talent-follow",
  templateUrl: "./follow.component.html",
  styleUrls: ["./follow.component.scss"]
})
export class FollowComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
