import {
  trigger,
  transition,
  style,
  animate,
  state,
  query,
  stagger,
  keyframes
} from "@angular/animations";

export const scrollAnimation = trigger("scrollAnimation", [
  state(
    "show",
    style({
      opacity: 1
    })
  ),
  state(
    "hide",
    style({
      opacity: 0
    })
  ),
  transition("show => hide", animate("400ms ease-out")),
  transition("hide => show", animate("400ms ease-in"))
]);

export const actionsAnimation = trigger("actionsAnimation", [
  state(
    "show",
    style({
      display: "block",
      opacity: 1
    })
  ),
  state(
    "hide",
    style({
      display: "none",
      opacity: 0
    })
  ),
  transition("show => hide", animate("400ms ease-out")),
  transition("hide => show", animate("400ms ease-in"))
]);

export const fieldAnimation = trigger("fieldAnimation", [
  state(
    "show",
    style({
      transform: "translateY(0%)",
      opacity: 1
    })
  ),
  state(
    "hide",
    style({
      transform: "translateY(5%)",
      opacity: 0
    })
  ),
  transition("show => hide", animate("500ms cubic-bezier(0.4, 0, 0.2, 1)")),
  transition("hide => show", animate("500ms cubic-bezier(0.4, 0, 0.2, 1)"))
]);
