import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { Announce } from "../class/announce";
import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ActionService {
  private subject: ReplaySubject<{ job: Announce }>;

  constructor(private http: HttpClient) {
    this.subject = new ReplaySubject<{ job: Announce }>(1);
  }

  sharing(job: Announce) {
    this.subject.next({ job: job });
  }

  getJob(): Observable<{ job: Announce }> {
    return this.subject.asObservable();
  }

  recommend(params: {
    idJob: string;
    name: string;
    lastName: string;
    email: string;
    worked: string;
    fit: string;
    criteria: string;
    why: string;
    noAdvantage: string;
    doEvent: string;
    linkedin: string;
  }): Observable<Object> {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    return this.http
      .post("/api/recommend", JSON.stringify(params), { headers })
      .pipe(
        map(response => {
          if (response === false) {
            throw new Error("Content not created");
          }
          return response;
        }),
        catchError(error => throwError(error.message || error))
      );
  }

  track(params: { type: string; device: string; browser: string }) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    return this.http
      .post("/api/tracking", JSON.stringify(params), { headers })
      .pipe(
        map(response => {
          if (response === false) {
            throw new Error("Content not created");
          }
          return response;
        }),
        catchError(error => throwError(error.message || error))
      );
  }
}
