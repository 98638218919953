import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, NavigationStart, ActivatedRoute } from "@angular/router";
import { ConnectService } from "../services/connect.service";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

@Component({
  selector: "talent-burger-menu",
  templateUrl: "./burger-menu.component.html",
  styleUrls: ["./burger-menu.component.scss"]
})
export class BurgerMenuComponent implements OnInit {
  overview: boolean;
  contact: boolean;
  howtalents: boolean;
  mytalents: boolean;
  settings: boolean;
  legalnotices: boolean;

  @ViewChild("checkInput") checkInput: ElementRef;
  @ViewChild("menu") menu: ElementRef;

  constructor(
    private connectService: ConnectService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.overview = false;
    this.howtalents = false;
    this.contact = false;
    this.mytalents = false;
    this.settings = false;
    this.legalnotices = false;

    switch (this.route.snapshot.url[0].path) {
      case "overview":
      case "job":
        this.overview = true;
        break;
      case "all-about-talent":
        this.howtalents = true;
        break;
      case "legal-notices":
        this.legalnotices = true;
        break;
      case "my-talents":
        this.mytalents = true;
        break;
      case "settings":
        this.settings = true;
        break;
    }
  }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        const segment = event.url.split("/");

        if (
          segment.indexOf("overview") !== -1 ||
          segment.indexOf("job") !== -1
        ) {
          this.overview = true;
        } else if (segment.indexOf("all-about-talent") !== -1) {
          this.howtalents = true;
        } else if (segment.indexOf("legal-notices") !== -1) {
          this.legalnotices = true;
        } else if (segment.indexOf("my-talents") !== -1) {
          this.mytalents = true;
        }
      });
  }

  toggleMenu() {
    this.menu.nativeElement.classList.toggle("fixed");
  }

  unMenu() {
    this.checkInput.nativeElement.checked = !this.checkInput.nativeElement
      .checked;
  }

  logout(event) {
    event.preventDefault();
    const res: Subscription = this.connectService.logout();
    res.add(() => this.router.navigate(["/connect"]));
  }
}
