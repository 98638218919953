import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, UrlSegment } from "@angular/router";

@Component({
  selector: "talent-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./sass/styles.scss"]
})
export class HeaderComponent implements OnInit {
  isAnnounce: boolean = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.activeAnnounce();
  }

  activeAnnounce() {
    this.route.url.subscribe((url: UrlSegment[]) => {
      url.forEach(segment => {
        this.isAnnounce =
          segment.path == "job" ? true : this.isAnnounce || false;
      });
      url.length === 0 ? (this.isAnnounce = true) : "";
    });
  }
}
