import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef
} from "@angular/core";
import { PlatformLocation } from "@angular/common";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { retry } from "rxjs/operators";

import { OverviewService } from "../services/overview.service";
import { Announce } from "../class/announce";
import { ConnectService } from "../services/connect.service";
import { ActionService } from "../services/action.service";
import { scrollAnimation, actionsAnimation } from "./transition";
import { GoogleAnalyticsService } from "../services/googleanalytics.service";
import { timer } from "rxjs";

@Component({
  selector: "talent-announce",
  templateUrl: "./announce.component.html",
  styleUrls: ["./sass/styles.scss"],
  animations: [scrollAnimation, actionsAnimation]
})
export class AnnounceComponent implements OnInit {
  announce: Announce;
  badNews: boolean;
  connected: boolean;
  protocol: string;
  baseURI: string;
  mailto: string;

  displayCorpus: string;
  displayMenu: string;
  displayActions: { share: string; recommend: string };

  actionsContainerStyle: { width: string };
  menuStyle: {
    left: string;
    top: string;
  };
  rerollMenuStyle: boolean;

  @ViewChild("Announce") announceRef: ElementRef;
  @ViewChild("ContainerAnnonce") containerAnnounceRef: ElementRef;
  @ViewChild("Menu") containerMenu: ElementRef;

  constructor(
    private overviewService: OverviewService,
    private connectService: ConnectService,
    private route: ActivatedRoute,
    private actionService: ActionService,
    private googleAnalytics: GoogleAnalyticsService,
    platformLocation: PlatformLocation
  ) {
    this.displayCorpus = "show";
    this.displayMenu = "hide";
    this.displayActions = {
      share: "hide",
      recommend: "hide"
    };
    this.badNews = false;

    this.menuStyle = {
      left: "0",
      top: "0"
    };
    this.rerollMenuStyle = true;

    this.baseURI = (platformLocation as any).location.hostname + "/job/";
    this.protocol = (platformLocation as any).location.protocol + "//";
  }

  ngOnInit() {
    this.connectService.isLoggedIn().subscribe(logued => {
      this.connected = logued;
      if (!this.connected) {
        this.googleAnalytics.sendEvent(
          "Page view",
          "talent",
          "anonyme job view"
        );
      }

      this.actionService
        .track({
          type: "job open",
          device: navigator.appVersion,
          browser: navigator.userAgent
        })
        .pipe(retry(3))
        .subscribe();
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.overviewService
        .getAnnounce(params.get("id"))
        .subscribe((announce: Announce) => {
          if (announce.hasOwnProperty("created") && announce.created !== null) {
            announce.date = new Date(parseInt(announce.created + "000"));
            this.announce = announce;

            this.mailto =
              "mailto:talent@ardian.com?subject=Applying to " +
              this.announce.profession +
              "&body=" +
              this.protocol +
              this.baseURI +
              this.announce.id;

            this.actionService.sharing(this.announce);

            this.menuStyling();
          } else {
            this.badNews = true;
          }
        });
    });
    window.scrollTo(0, 0);
  }

  @HostListener("window:scroll", ["$event"])
  manageScroll() {
    this.menuStyling();

    let scrollPosition = window.pageYOffset;
    let announceBottom = this.announceRef
      ? this.announceRef.nativeElement.clientHeight
      : 0;

    if (
      scrollPosition >= 150 &&
      scrollPosition <= announceBottom - 500 &&
      this.connected
    ) {
      this.displayMenu = "show";
    } else {
      this.displayMenu = "hide";
    }
  }

  @HostListener("window:resize", ["$event"])
  manageResize() {
    this.rerollMenuStyle = true;
    this.menuStyling();
  }

  menuStyling() {
    if (this.rerollMenuStyle && this.containerMenu !== undefined) {
      this.rerollMenuStyle = false;

      let refSizer =
        this.containerAnnounceRef.nativeElement.offsetLeft -
        this.containerMenu.nativeElement.clientWidth -
        10;

      refSizer +=
        this.containerAnnounceRef.nativeElement.offsetLeft <= 227 ? 100 : 0;

      this.menuStyle = {
        left: refSizer + "px",
        top: window.innerHeight / 2 - 90 + "px"
      };
    }
  }

  actionsManagement(action: string, type: string) {
    this.googleAnalytics.sendEvent("Job action", "talent", action + " action");

    if (type === "show") {
      this.actionService
        .track({
          type: action,
          device: navigator.appVersion,
          browser: navigator.userAgent
        })
        .pipe(retry(3))
        .subscribe();
    }

    this.displayCorpus = type === "hide" ? "show" : "hide";
    this.displayActions[action] = type;
  }
}
