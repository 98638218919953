import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class OverviewService {
  constructor(private http: HttpClient) {}

  getList() {
    const headers = {
      Accept: "application/json"
    };

    return this.http.get("/api/overview", { headers });
  }

  getAnnounce(id: string) {
    const headers = {
      Accept: "application/json"
    };
    const params = { id: id };

    return this.http.get("/api/announce/" + id, { headers, params });
  }
}
