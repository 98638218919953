import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { map, catchError, tap } from "rxjs/operators";
import { throwError, Observable, of } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ConnectService {
  isLogued: number;
  private _officeId: number;
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.isLogued = -1;
    this._officeId = null;
  }

  get officeId(): number {
    return this._officeId;
  }
  isLoggedIn(force: boolean = false): Observable<boolean> {
    return this.isLogued !== -1 && !force
      ? of(this.isLogued === 1)
      : this.http.get("/namtdyb/login_status?_format=json").pipe(
          map((response: number) => {
            this.isLogued = response;
            return response === 1;
          })
        );
  }

  connect(login: string, pass: string) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    return this.http
      .post(
        "/namtdyb/login?_format=json",
        '{"name":"' + login + '", "pass":"' + pass + '"}',
        { headers }
      )
      .pipe(
        map(response => {
          const expires = new Date(new Date().getTime() + 2000000000);
          this.cookieService.set(
            "talent_logout_token",
            response["logout_token"],
            expires
          );
          const myOffice = this.http
            .get("/api/return-user-data")
            .subscribe(e => {
              this._officeId = e["officeId"];
            });
          return true;
        }),
        catchError(error => throwError(error.message || error))
      );
  }

  deleteCookies() {
    this.cookieService.delete("talent_logout_token");
  }

  logout() {
    if (this.cookieService.check("talent_logout_token")) {
      const token: string = this.cookieService.get("talent_logout_token");
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json"
      };

      let params = new HttpParams();
      params = params.append("token", token);

      return this.http
        .post("/namtdyb/logout?_format=json&token=" + token, {
          headers
        })
        .subscribe(() => {
          this.deleteCookies();
          return true;
        });
    } else {
      this.hardlogout();
    }
  }

  hardlogout() {
    this.deleteCookies();
    window.location.pathname = "/namtdyb/logout";
  }
}
