import { Component, OnInit } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "talent-logo",
  templateUrl: "./logo.component.html",
  styleUrls: ["./sass/styles.scss"]
})
export class LogoComponent implements OnInit {
  in: boolean;
  display: boolean;
  connect: boolean;

  constructor(private router: Router) {
    this.in = false;
    this.display = true;
    this.connect = false;
  }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        const segment = event.url.split("/");

        if (
          segment.indexOf("job") !== -1 ||
          segment.indexOf("overview") !== -1
        ) {
          this.in = true;
          this.display = true;
          this.connect = false;
        } else if (segment.indexOf("connect") !== -1) {
          this.connect = true;
        }
      });
  }
}
