import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { element } from "protractor";

@Component({
  selector: "talent-filter-mobile",
  templateUrl: "./filter-mobile.component.html",
  styleUrls: ["./filter-mobile.component.scss"],
})
export class FilterMobileComponent implements OnInit {
  @Input() filterOffice: Array<String>;
  @Input() filterTeam: Array<String>;
  @Input() filterContract: Array<String>;
  @Input() filterText: String;
  displayAll: boolean = false;
  officeToggle: boolean = false;
  teamToggle: boolean = false;
  contractToggle: boolean = false;

  @Output() filterEvent = new EventEmitter<any>();
  @Output() resetEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  filterEventEmit($event) {
    if ($event.target.selectedIndex === -1) {
      $event.target.data = [];
    } else {
      $event.target.data = [$event.target.value];
    }
    this.filterEvent.emit($event);
  }
  resetEventEmit($event) {
    const myForm = <HTMLFormElement>document.getElementById("filtersForm");
    var elements = document.getElementsByClassName(
      "content__filters__container__element__head"
    );
    this.officeToggle = false;
    this.teamToggle = false;
    this.contractToggle = false;

    myForm.reset();
    this.resetEvent.emit($event);
  }
  changeBackground($element) {
    if ($element.target.value !== "") {
      switch ($element.target.name) {
        case "office":
          this.officeToggle = true;
          break;
        case "team":
          this.teamToggle = true;
          break;
        case "contract":
          this.contractToggle = true;
          break;
      }
    }
  }

  resetFilter($string) {
    var mySelect;
    var event = new Event("change");
    switch ($string) {
      case "office":
        mySelect = <HTMLFormElement>document.getElementById("office");
        mySelect.selectedIndex = -1;
        this.officeToggle = false;
        mySelect.dispatchEvent(event);
        break;
      case "team":
        mySelect = <HTMLFormElement>document.getElementById("team");
        mySelect.selectedIndex = -1;
        mySelect.dispatchEvent(event);
        this.teamToggle = false;
        break;
      case "contract":
        mySelect = <HTMLFormElement>document.getElementById("contract");
        mySelect.selectedIndex = -1;

        mySelect.dispatchEvent(event);

        this.contractToggle = false;
        break;
    }
  }
}
