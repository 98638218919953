import {
  Component,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  AfterContentInit
} from "@angular/core";
import { Announce } from "../../class/announce";
import { timer, Observable } from "rxjs";
import { fieldAnimation, actionsAnimation } from "../transition";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators
} from "@angular/forms";
import { ActionService } from "src/app/services/action.service";

@Component({
  selector: "talent-recommend",
  templateUrl: "./recommend.component.html",
  styleUrls: ["./recommend.component.scss"],
  animations: [fieldAnimation, actionsAnimation]
})
export class RecommendComponent implements OnInit, AfterContentInit {
  display: string;
  redTitle: string;
  inputTouched: [];

  submitted: boolean;
  errorOnSubmit: boolean;
  submitSuccess: string;

  nameCtrl: FormControl;
  lastNameCtrl: FormControl;
  emailCtrl: FormControl;
  linkedin: FormControl;
  recommendForm: FormGroup;
  workedCtrl: FormControl;
  fitCtrl: FormControl;
  criteriaCtrl: FormControl;
  whyCtrl: FormControl;
  noAdvantageCtrl: FormControl;
  doEventCtrl: FormControl;
  descForm: FormGroup;

  loaderStyle: {};
  @ViewChild("RecoContainer") recoContainerRef: ElementRef;

  @Input() job: Announce;

  @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(private actionService: ActionService, fb: FormBuilder) {
    this.display = "hide";
    this.errorOnSubmit = false;
    this.submitSuccess = "hide";
    this.submitted = false;

    this.redTitle = "RECOMMEND";

    this.formInit(fb);
  }

  ngOnInit() {
    this.computeStyle();
  }

  ngAfterContentInit() {
    timer(1).subscribe(() => (this.display = "show"));
  }

  private formInit(fb: FormBuilder) {
    this.inputTouched = [];
    this.workedCtrl = fb.control("", [Validators.required]);
    this.fitCtrl = fb.control("", [Validators.required]);
    this.criteriaCtrl = fb.control("", [Validators.required]);
    this.whyCtrl = fb.control("", [Validators.required]);
    this.noAdvantageCtrl = fb.control("", [Validators.required]);
    this.doEventCtrl = fb.control("", [Validators.required]);
    this.descForm = fb.group({
      worked: this.workedCtrl,
      fit: this.fitCtrl,
      criteria: this.criteriaCtrl,
      why: this.whyCtrl,
      noAdvantage: this.noAdvantageCtrl,
      doEvent: this.doEventCtrl
    });

    this.nameCtrl = fb.control("", [Validators.required]);
    this.lastNameCtrl = fb.control("", [Validators.required]);
    this.emailCtrl = fb.control("", [Validators.required, Validators.email]);
    this.linkedin = fb.control("");
    this.recommendForm = fb.group({
      name: this.nameCtrl,
      lastName: this.lastNameCtrl,
      email: this.emailCtrl,
      description: this.descForm,
      linkedin: this.linkedin
    });
  }

  labelFocus(type: string) {
    this.inputTouched[type] = true;
  }

  labelFocusOut(type: string) {
    switch (type) {
      case "name":
        this.nameCtrl.value === "" ? (this.inputTouched[type] = false) : "";
        break;
      case "lastName":
        this.lastNameCtrl.value === "" ? (this.inputTouched[type] = false) : "";
        break;
      case "email":
        this.emailCtrl.value === "" ? (this.inputTouched[type] = false) : "";
        break;
      case "worked":
        this.workedCtrl.value === "" ? (this.inputTouched[type] = false) : "";
        break;
      case "fit":
        this.fitCtrl.value === "" ? (this.inputTouched[type] = false) : "";
        break;
      case "criteria":
        this.criteriaCtrl.value === "" ? (this.inputTouched[type] = false) : "";
        break;
      case "why":
        this.whyCtrl.value === "" ? (this.inputTouched[type] = false) : "";
        break;
      case "noAdvantage":
        this.noAdvantageCtrl.value === ""
          ? (this.inputTouched[type] = false)
          : "";
        break;
      case "doEvent":
        this.doEventCtrl.value === "" ? (this.inputTouched[type] = false) : "";
        break;
      case "linkedin":
        this.linkedin.value === "" ? (this.inputTouched[type] = false) : "";
        break;
    }
  }

  closeRecommend() {
    this.redTitle = "RECOMMEND";
    this.closeAction.emit();
  }

  recommend() {
    this.errorOnSubmit = false;
    this.submitted = true;

    const res: Observable<Object> = this.actionService.recommend({
      idJob: this.job.id,
      name: this.nameCtrl.value,
      lastName: this.lastNameCtrl.value,
      email: this.emailCtrl.value,
      worked: this.workedCtrl.value,
      fit: this.fitCtrl.value,
      criteria: this.criteriaCtrl.value,
      why: this.whyCtrl.value,
      noAdvantage: this.noAdvantageCtrl.value,
      doEvent: this.doEventCtrl.value,
      linkedin: this.linkedin.value
    });

    res.subscribe(
      ok => {
        this.submitted = false;
        this.submitSuccess = "show";
        this.redTitle = "RECOMMENDED";
      },
      error => {
        this.submitted = false;
        this.errorOnSubmit = true;
      }
    );
  }

  computeStyle($event = null) {
    let scroll = 0;
    if ($event !== null && this.recoContainerRef !== undefined) {
      scroll = this.recoContainerRef.nativeElement.scrollTop;
    }

    this.loaderStyle = {
      top: "calc(50vh + " + scroll + "px - 80px)"
    };
  }
}
