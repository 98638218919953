import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { OverviewService } from "../services/overview.service";
import { Announce } from "../class/announce";
import { listAnimation } from "./transition";
import { timer } from "rxjs";

@Component({
  selector: "talent-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./sass/styles.scss"],
  animations: [listAnimation],
})
export class OverviewComponent implements OnInit {
  constList: Array<Announce>;
  list: Array<Announce>;
  sortDate: { name: string; active: boolean };
  filterOffice: Array<String>;
  filterTeam: Array<String>;
  filterContract: Array<String>;
  originalOfficeFilters: Array<String>;
  originalContractFilters: Array<String>;
  originalTeamFilters: Array<String>;
  filterText: String;
  filters: {
    date: boolean;
    office: Array<String>;
    team: Array<String>;
    contractType: Array<String>;
  };
  responsive: boolean;

  constructor(private overviewService: OverviewService) {
    this.sortDate = { name: "By Publishing Date", active: true };
    this.filters = { date: false, office: [], team: [], contractType: [] };
    this.filterOffice = [];
    this.filterTeam = [];
    this.filterContract = [];
    this.originalContractFilters = [];
    this.originalOfficeFilters = [];
    this.originalTeamFilters = [];
    this.filterText = "";
    this.isResponsive();
  }

  buildOriginalFilters(list) {
    this.originalOfficeFilters = [];
    this.originalTeamFilters = [];
    this.originalContractFilters = [];
    list.forEach((announce) => {
      // Pour chaque annonce
      // Si le filtre office ne contient pas la valeur office de l'annonce et qu'elle est non nulle
      if (
        !this.originalOfficeFilters.includes(announce.office) &&
        announce.office !== ""
      ) {
        this.originalOfficeFilters.push(announce.office);
      }
      if (
        !this.originalTeamFilters.includes(announce.team) &&
        announce.team !== ""
      ) {
        if (announce.parentTeam !== null) {
          this.originalTeamFilters.push(announce.parentTeam);
        } else {
          this.originalTeamFilters.push(announce.team);
        }
      }
      if (
        !this.originalContractFilters.includes(announce.contractType) &&
        announce.contractType !== ""
      ) {
        this.originalContractFilters.push(announce.contractType);
      }
      announce.date = new Date(parseInt(announce.created + "000"));
    });
  }
  buildFilters(list) {
    // Reset des filtres
    this.filterOffice = [];
    this.filterTeam = [];
    this.filterContract = [];
    list.forEach((announce) => {
      // Pour chaque annonce
      // Si le filtre office ne contient pas la valeur office de l'annonce et qu'elle est non nulle
      if (
        !this.filterOffice.includes(announce.office) &&
        announce.office !== ""
      ) {
        this.filterOffice.push(announce.office);
      }
      if (!this.filterTeam.includes(announce.team) && announce.team !== "") {
        if (announce.parentTeam !== null) {
          this.filterTeam.push(announce.parentTeam);
        } else {
          this.filterTeam.push(announce.team);
        }
      }
      if (
        !this.filterContract.includes(announce.contractType) &&
        announce.contractType !== ""
      ) {
        this.filterContract.push(announce.contractType);
      }
      announce.date = new Date(parseInt(announce.created + "000"));
    });
  }
  ngOnInit() {
    // FAire une fonction
    this.overviewService.getList().subscribe((list: Array<Announce>) => {
      this.constList = list;
      this.list = list;
      this.buildOriginalFilters(list);
      this.buildFilters(list);
    });
  }

  changeFilter($event) {
    switch ($event.target.name) {
      default:
        this.filters.date = !this.filters.date;
        break;
      case "office":
        this.filters.office = $event.target.data;
        break;
      case "team":
        this.filters.team = $event.target.data;
        break;
      case "contract":
        this.filters.contractType = $event.target.data;
        break;
    }
    this.changeOrder();
    this.updateFilterText();
    this.buildFilters(this.list);
  }

  resetFilters() {
    this.sortDate = { name: "By Publishing Date", active: true };
    this.filters = { date: false, office: [], team: [], contractType: [] };
    this.filterText = "";
    this.list = this.constList;
    this.changeOrder();
    this.buildFilters(this.list);
  }

  updateFilterText() {
    let tmp = [];
    if (
      typeof this.filters.office !== "undefined" &&
      this.filters.office.length > 0
    ) {
      tmp.push(this.filters.office);
    }
    if (
      typeof this.filters.team !== "undefined" &&
      this.filters.team.length > 0
    ) {
      tmp.push(this.filters.team);
    }
    if (
      typeof this.filters.contractType !== "undefined" &&
      this.filters.contractType.length > 0
    ) {
      tmp.push(this.filters.contractType);
    }
    if (tmp.length > 0) {
      this.filterText = tmp.join(", ");
      if (this.filterText.length >= 40) {
        this.filterText = this.filterText.substring(0, 40) + "...";
      }
    } else {
      this.filterText = "Office, Team or Contract";
    }
    return;
  }

  toggleSelect() {
    // if (window.innerWidth <= 794) {
    //   this.optionSelected.nativeElement.classList.toggle("open");
    //   this.optionsFilter.forEach((element: ElementRef) => {
    //     element.nativeElement.classList.toggle("display");
    //   });
    // }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.isResponsive();
  }

  isResponsive() {
    this.responsive = window.innerWidth <= 794;
  }
  removeDuplicate(inputArray) {
    inputArray = inputArray.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    return inputArray;
  }

  private changeOrder() {
    let temp = Array.from(this.constList);
    console.log(this.filters);
    Object.keys(this.filters).forEach((element) => {
      if (
        (typeof this.filters[element] !== "undefined" &&
          this.filters[element].length > 0) ||
        element == "date"
      ) {
        if (element === "date") {
          if (this.filters.date === false) {
            temp.sort(function (a, b) {
              return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
            });
            temp.reverse();
          } else {
            temp.sort(function (a, b) {
              return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
            });
          }
        } else if (element === "team" && this.filters[element] !== []) {
          temp = temp.filter((announce) => {
            if (
              !this.filters[element].includes(announce[element]) &&
              !this.filters[element].includes(announce["parentTeam"])
            ) {
              return false;
            }
            return announce;
          });
        } else if (this.filters[element] !== "") {
          temp = temp.filter((announce) => {
            if (this.filters[element].includes(announce[element])) {
              return announce;
            }
            return false;
          });
        }
      }
    });
    temp = this.removeDuplicate(temp);
    this.list = temp;

    timer(10).subscribe(() => {
      this.list = temp;
    });
  }
}
