import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ROUTES } from "./app.routes";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ServiceWorkerModule } from "@angular/service-worker";
import { ErrorInterceptor } from "./guards/error.interceptor";

import { environment } from "../environments/environment";

import { CookieService } from "ngx-cookie-service";
import { ClipboardModule } from "ngx-clipboard";

import { AppComponent } from "./app.component";
import { ConnectComponent } from "./connect/connect.component";
import { HeaderComponent } from "./header/header.component";
import { LogoComponent } from "./logo/logo.component";
import { OverviewComponent } from "./overview/overview.component";
import { AnnounceComponent } from "./announce/announce.component";
import { ShareComponent } from "./announce/share/share.component";
import { RecommendComponent } from "./announce/recommend/recommend.component";
import { RecoSuccessComponent } from "./announce/recommend/reco-success/reco-success.component";
import { BurgerMenuComponent } from "./burger-menu/burger-menu.component";
import { DocumentComponent } from "./document/document.component";
import { FollowComponent } from "./follow/follow.component";
import { MytalentsComponent } from "./mytalents/mytalents.component";
import { LoaderComponent } from "./loader/loader.component";
import { PopinComponent } from "./popin/popin.component";

import { ConnectService } from "./services/connect.service";
import { ErrorService } from "./services/error.service";
import { OverviewService } from "./services/overview.service";
import { ActionService } from "./services/action.service";
import { DocumentService } from "./services/document.service";
import { MytalentsService } from "./services/mytalents.service";
import { SettingsService } from "./services/settings.service";
import { GoogleAnalyticsService } from "./services/googleanalytics.service";
import { PopinService } from "./services/popin.service";
import { FilterMobileComponent } from "./overview/filter-mobile/filter-mobile.component";
import { FilterDesktopComponent } from "./overview/filter-desktop/filter-desktop.component";
import { SettingsComponent } from "./settings/settings.component";

@NgModule({
  declarations: [
    AppComponent,
    OverviewComponent,
    ConnectComponent,
    AnnounceComponent,
    HeaderComponent,
    LogoComponent,
    ShareComponent,
    RecommendComponent,
    RecoSuccessComponent,
    BurgerMenuComponent,
    DocumentComponent,
    FollowComponent,
    MytalentsComponent,
    SettingsComponent,
    LoaderComponent,
    PopinComponent,
    FilterMobileComponent,
    FilterDesktopComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    }),
    ClipboardModule
  ],
  providers: [
    GoogleAnalyticsService,
    ConnectService,
    OverviewService,
    MytalentsService,
    CookieService,
    ErrorService,
    ActionService,
    DocumentService,
    PopinService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
