import { Component, OnInit } from "@angular/core";

@Component({
  selector: "talent-reco-success",
  templateUrl: "./reco-success.component.html",
  styleUrls: ["./reco-success.component.scss"]
})
export class RecoSuccessComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
