import { Routes } from "@angular/router";
import { ConnectComponent } from "./connect/connect.component";
import { OverviewComponent } from "./overview/overview.component";
import { AnnounceComponent } from "./announce/announce.component";
import { LoggedInGuard } from "./guards/logguedin.guard";
import { SettingsComponent } from "./settings/settings.component";
import { DocumentComponent } from "./document/document.component";
import { MytalentsComponent } from "./mytalents/mytalents.component";

export const ROUTES: Routes = [
  {
    path: "connect",
    component: ConnectComponent
  },
  { path: "job/:id", component: AnnounceComponent },
  {
    path: "overview",
    canActivate: [LoggedInGuard],
    component: OverviewComponent
  },
  {
    path: "all-about-talent",
    canActivate: [LoggedInGuard],
    component: DocumentComponent
  },
  {
    path: "legal-notices",
    canActivate: [LoggedInGuard],
    component: DocumentComponent
  },
  {
    path: "my-talents",
    canActivate: [LoggedInGuard],
    component: MytalentsComponent
  },
  {
    path: "settings",
    canActivate: [LoggedInGuard],
    component: SettingsComponent
  },
  { path: "", redirectTo: "/connect", pathMatch: "full" }
];
